import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

import { Link } from "gatsby";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  "Legal Notice": "en/legal-notice"

};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "de",
    href: "/de/allgemeine-geschaeftsbedingungen"
  },
  {
    hreflang: "en",
    href: "/en/legal-notice"
  },
  {
    hreflang: "x-default",
    href: "/en/legal-notice"
  }
];

const LegalNotice = props => {
  return (
    <Layout location={props.location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Legal Notices"
          description="Legal Notices about Matthias Kupperschmidts services in the sector of online marketing; this includes search engine optimization, web analytics implementations, consultancy on online marketing"
          lang="en"
          alternateLangs={alternateLangs}
        />
        <MainContent
          article={false}

        >
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

          <H as="h1">Legal Notice</H>

          <p>Matthias Kupperschmidt offers various services (referred to as “services” below) in the sector of online marketing; this includes search engine optimization, web analytics implementations, consultancy on online marketing topics as well as training courses and workshops.</p>

          <p>The use of the provided services is permitted only for the intended purpose described in these General Terms and Conditions and/or the order. In addition, any use or processing for other purposes is only permitted under the strict conditions of Danish data protection laws, in particular the Data Protection Act and the General Data Protection Regulation (GDPR) of the EU. Any improper use or exploitation is not permitted. In particular, the complete use of the provided services and content for commercial purposes of any kind and in any media form (printed, electronic, on CD, etc.) as well as the readout of directories, databases, etc., for the aforementioned purposes or for purposes of any other commercial use is not permitted and will be prosecuted by the provider according to applicable law by exhausting all available legal remedies.</p>
          <p>The availability of individual services as well as the correctness and completeness of the content/entries/data is not warranted.</p>
          <p>These Terms and Conditions govern the relations between Matthias Kupperschmidt and its customers and contractual partners (referred to as “contractual partner” below)</p>

          <p>Section 1 Scope</p>
          <p>The services of Matthias Kupperschmidt are carried out solely on the basis of these General Terms and Conditions. Hence they also apply to all future business relations even if they are not expressly agreed upon again.</p>
          <p>The use of the services offered by Matthias Kupperschmidt to the contractual partner is exclusively governed by these General Terms and Conditions (CTG) as well as the special terms and conditions of the respective products/services. For offers, services, individual projects, promotions and other services, especially project services beyond that, the conditions to be agreed upon in separate agreements apply.</p>
          <p>The general terms and conditions of contractual partners or other third parties are not applicable even if Matthias Kupperschmidt does not expressly object to them and/or renders its services without objection.</p>

          <p>Section 2 General scope of services</p>
          <p>Among other things, Matthias Kupperschmidt is active in the areas of search engine optimisation, web analytics, inbound marketing, consultancy on online marketing topics as well as training courses and workshops. The detailed description of the service ordered in each case can be seen from the respective order.</p>
          <p>The services and content offered by Matthias Kupperschmidt are provided and published by Matthias Kupperschmidt unless specifically identified otherwise. Any content that is made accessible by third parties is information provided by the respective creator, author or distributor and not information provided by Matthias Kupperschmidt. Matthias Kupperschmidt is therefore not responsible for the accuracy or reliability of this content.</p>
          <p>The services offered by Matthias Kupperschmidt are only an invitation to submit a proposal to conclude a contract. The contractual partner is bound to his declaration for ten working days. Confirmation in text form (e-mail suffices) by Matthias Kupperschmidt is required for the conclusion of a contract. A business contract then comes into being on the basis of the applicable GTC.</p>
          <p>Matthias Kupperschmidt is not required to conclude contracts. He is entitled to refuse the conclusion of contracts without giving reasons. Subsequent changes of and additions to the contracts require the written confirmation of Matthias Kupperschmidt. Separate agreements shall be entered into by the contractual partners for further services.</p>
          <p>Matthias Kupperschmidt also reserves the right to exclude contractual partners at a later time from the use of the services if there is evidence that the contractual partner misuses services or uses them in a way that might put the reputation of Matthias Kupperschmidt at risk. This applies in particular in the event that the contractual partner breaches duties from the contractual relationship or harms other contractual partners of Matthias Kupperschmidt.</p>
          <p>Insofar as a contractual partner uses software of the respective other contractual partner as part of the use of the services, the respective other contractual partner grants him – unless expressly regulated otherwise – a non-exclusive, non-transferable right of use, for his own use, that is limited in time to the duration of the contract and, in terms of content, limited to use within the meaning of the provision of the service. Further rights of use are not granted to the contractual partner.</p>
          <p>For maintenance purposes and to carry out repairs, Matthias Kupperschmidt is entitled to interrupt the provision of services or parts thereof to the extent that is reasonable for the contractual partner. In general, Matthias Kupperschmidt shall endeavour to perform maintenance and repair work at times during which the onerous impact of it will be as low as possible. This does not establish any redress or compensation claims on the part of the contractual partner unless Matthias Kupperschmidt is at fault.</p>
          <p>Matthias Kupperschmidt is entitled to enter into similar contractual relationships with competitors of the contractual partner. However, Matthias Kupperschmidt shall endeavour – without being obligated to do so – to protect the justified interests of the contractual partner in this regard and to avoid conflicts of interest. Matthias Kupperschmidt is entitled to provide partial services as well as services by third parties.</p>
          <p>Deadlines and dates requested by the contractual partner are only binding when Matthias Kupperschmidt has expressly confirmed them in writing and if the contractual partner has created in due time all the conditions necessary for the provision of the service that are within his sphere of influence.</p>
          <p>To the extent and as long as the contractual partner has not met any of his cooperation obligations required for the provision of the service, even after a written request by Matthias Kupperschmidt and the setting of a reasonable grace period, Matthias Kupperschmidt shall be released from fulfilling the obligation affected by this omission at the deadline agreed upon in the project contract. The deadline agreed upon in the order/contract for provision shall be extended accordingly.</p>


          <p>Section 3 Contracting, scope of contract</p>
          <p>Each service must be separately ordered by the contractual partner (individual order/service level agreement). The contracting parties shall provide each other mutually with the content required for order fulfilment. If tracking codes are integrated in this content, they are allowed to be changed or deleted by the respective other contractual partner only upon prior consultation and agreement.</p>
          <p>Matthias Kupperschmidt shall create an invoice for the contractual partner for each promotion carried out that clearly shows all the information specified in Item 1.</p>

          <p>Section 4 Obligations of the contractual partner</p>
          <p>The client undertakes to support the measures taken by Matthias Kupperschmidt. The client shall provide Matthias Kupperschmidt with all information and data required for order fulfilment.</p>
          <p>The contractual partner undertakes to ensure that the content provided by him and associated with the use of the services of Matthias Kupperschmidt does not violate applicable law of the state of Denmark; this applies in particular to illegal, immoral or pornographic content or such content that glorifies war or is suitable to pose a moral risk to children and young people or negatively affect their well-being.</p>
          <p>The contractual partner shall also report all events and circumstances that might be of importance for the proper and timely execution of the contract. This also applies to events and circumstances the contractual partner only becomes aware of during the term of the contract. Project delays due to the fact that the contractual partner does not meet required cooperation obligations or does not meet them in due time are at the expense of the contractual partner and do not affect the remuneration claim of Matthias Kupperschmidt.</p>
          <p>Any breach of the obligation specified in Item 1 entitles Matthias Kupperschmidt to terminate the contract between Matthias Kupperschmidt and the contractual partner immediately. Matthias Kupperschmidt reserves the right to immediate termination even if there is only a suspicion that the contractual partner disseminates or tolerates content as described in Item 1. Matthias Kupperschmidt is entitled but not obligated to monitor the legality of the content.</p>
          <p>The contractual partner undertakes strictly to comply with the technical and content specifications demanded by Matthias Kupperschmidt. Any delays and/or changes regarding the services provided by Matthias Kupperschmidt resulting from non-compliance with these specifications are at the expense of the contractual partner and do not establish any claims against Matthias Kupperschmidt.</p>
          <p>In addition, the contractual partner is responsible that all information and documents required for the fulfilment of his contractual obligations and the proper implementation of the collaboration is made available to Matthias Kupperschmidt in due time, completely and in a form that is suitable for fulfilment prior to the agreed implementation of the contract.</p>
          <p>If one party is prevented from providing the contractual service and/or content due to circumstances for which it is not responsible, this party shall inform the respective other party accordingly and transmit without delay content and/or services that have been adapted to the circumstances. If the aforementioned circumstances last longer than 24 hours, the parties shall come to a mutual agreement on how to proceed further.</p>
          <p>The contractual partner is not entitled to use, reproduce, disseminate, publish, change or utilise in any other way the brands and/or logos of Matthias Kupperschmidt, its customers and partners unless Matthias Kupperschmidt has expressly consented to it in writing beforehand. Furthermore, the contractual partner is under obligation to respect third-party copyrights or trademark rights, both German and foreign.</p>
          <p>The contractual partner agrees to the forwarding of his content, data and other information to third parties to the extent it is necessary and required for the fulfilment of the contractual relationship between the parties.</p>

          <p>Section 5 Term of contract/termination</p>
          <p>Cost estimates by Matthias Kupperschmidt are not binding. Matthias Kupperschmidt undertakes to notify the contractual partner immediately of any variance in the actual costs in comparison with the estimated figure if the variance is more than ten percent.</p>
          <p>The contract concluded between Matthias Kupperschmidt and the contractual partner ends with the expiration of the agreed term. As for the rest, the respective contract term can be seen from the offer.</p>
          <p>If a minimum term had been agreed upon with the contractual partner, the agreement is automatically extended by the agreed term unless the extension is objected to at the latest 30 days prior to the expiration of the relevant contract term.</p>
          <p>Contracts that have been concluded for an indefinite period of time can be terminated by both parties in writing at any time with a notice period of three months to the end of the month.</p>
          <p>The right to terminate the contract for good cause remains unaffected. A good cause is given in particular in the event that one of the parties breaches essential duties and obligations arising from the contract or applicable law of the state of Denmark and does not restore the contractual state despite a written warning from the other party within 7 days after receipt of the warning. The warning must specify the breach of an obligation or duty and point out the possibilities of termination for good cause. Essential contractual obligations are in particular obligations to maintain confidentiality.</p>

          <p>For Matthias Kupperschmidt, a good cause is given if</p>
          <p>the opening of insolvency or bankruptcy proceedings against the assets of the contractual partner has been filed</p>
          <ol>
            <li>any other culpable breach of essential contractual obligations or duties of a contractual partner if they had not been immediately eliminated despite a warning.</li>
          </ol>
          <p>Until the termination takes effect, each contractual partner retains his claim to any fees and commissions that will still be incurred. Until this point in time, the contractual partners are also obligated to meet their contractual obligations. Any obligations that go beyond the contract term, in particular those relating to data protection and confidentiality, shall remain unaffected by this.</p>
          <p>All terminations must be done in writing or e-mail.</p>

          <p>Section 6 Remuneration and due dates</p>
          <p>The agreed remuneration is due at the end of the consultancy and/or the implementation of measures. An acceptance is not required.</p>
          <p>Matthias Kupperschmidtis entitled to adequate payment already before the end of the consultancy and the implementation of the measures. In case of doubt, the adequacy is based on the working time already provided. In the case of permanent consultancy, Matthias Kupperschmidt is entitled to invoice services immediately after they have been provided. Remuneration is based on the offer made by Matthias Kupperschmidt.</p>

          <p>Section 7 Money transfer, payment terms</p>
          <p>The remuneration, or distribution of sales, is always done according to the breakdown specified in the individual order.</p>
          <p>Unless agreed upon otherwise in the individual order, settlement is made by Matthias Kupperschmidt on a monthly basis. All sales figures and fees are net sums; VAT is shown separately.</p>
          <p>Matthias Kupperschmidt is entitled to demand advance payments of up to 100% of the order volume; advance payments are due upon signing the contract.</p>
          <p>In the event of late payment, Matthias Kupperschmidt is entitled to charge default interest to the contractual partner – while simultaneously reserving the right to assert further damage interest – in the amount of five percentage points above the respective base interest. The default interest is lower if the contractual partner proves a lower damage.</p>
          <p>The contractual partner has a right of retention or right to offset only insofar as the claims of the contractual partner are undisputed and have been legally established.</p>


          <p>Section 8 Property rights and copyright protection, rights of use</p>
          <p>The copyrights and other property rights to the services provided by Matthias Kupperschmidt remain with Matthias Kupperschmidt.</p>
          <p>Matthias Kupperschmidt grants the contractual partner non-exclusive, temporary and territorially unlimited rights of use for the exploitation of the complete service that has been delivered as per contract (work results).</p>
          <p>The contractual partner is not entitled, however, to sell the delivered content and information to third parties or make them otherwise available to third parties outside their intended purpose.</p>
          <p>Changes to the services provided by Matthias Kupperschmidt to be carried out by the contractual partner or a third party tasked with it by the contractual partner are permitted only with the express consent of Matthias Kupperschmidt.</p>
          <p>The exercise of the rights of use is permitted only if the contractual partner is not in arrears with a payment at that point in time.</p>
          <p>On the basis of agreements with employees, agents and any third-party right holders, Matthias Kupperschmidt ensures that the contractual partner can freely exercise the contractual rights of use.</p>

          <p>Section 9 Warranty and liability</p>
          <p>Matthias Kupperschmidt shall operate its services within the framework of its technical possibilities and does not assume any warranty or guarantee for uninterrupted and/or constant error-free availability of its services; in particular, not for line outages and/or connection failures, hardware and/or software errors nor for the actions by third parties (e.g. viruses or “denial of service” attacks). The contracting parties agree that the occurrence of such interruptions and/or faults do not establish redress or compensation claims of any kind.</p>
          <p>Defects are variances from the service description that limit the contractual use. Any defects must be reported to Matthias Kupperschmidt in detail. The parties shall cooperate in defect analysis and troubleshooting. Rectification is performed within the scope of the liability for defects and does not involve costs for the contractual partner.</p>
          <p>The contractual partner shall grant Matthias Kupperschmidt an adequate period for rectification. If the rectification is not successful even within a second, adequate grace period (at least, however, within 15 working days), the contractual partner is entitled to demand a reduction in payment or compensation for damage due to non-fulfilment if he has announced such a step when setting the grace period.</p>
          <p>Claims for defects on the part of the contractual partner can no longer be asserted if he has carried out changes to the services himself or had such changes carried out by third parties without prior written consent, unless the contractual partner can prove that the defects do not result from these changes or that these changes do not hinder the defect analysis and elimination or do so only marginally.</p>
          <p>Claims for defects are excluded in principle if the contractual partner uses the services at conditions of use that vary from those specified in the agreement. It is the responsibility of the contractual partner to prove that the defect was not caused by such a variance.</p>
          <p>Claims are excluded for damage under the Danish sale of goods act on account of deficiencies at the beginning of the contract for which Matthias Kupperschmidt is not responsible as well as on account of only negligent deficiencies.</p>
          <p>Claims for defects shall expire one year after acceptance; in the case of services not capable or not in need of acceptance, one year after delivery/takeover or after notification of completion and/or provision of the service.</p>
          <p>Matthias Kupperschmidt is not liable for technical faults whose causes are not within the sphere of responsibility of Matthias Kupperschmidt or for damage caused by force majeure. In exceptional cases, it can occur that individual data, services or other technical or actual activities cannot be captured or correctly invoiced by Matthias Kupperschmidt. Any claim based upon this – in particular claims for damage or value replacement – against Matthias Kupperschmidt on the part of the contractual partner is excluded.</p>
          <p>Matthias Kupperschmidt is only liable for damage due to the breach of essential contractual obligations or due to the absence of assured features and properties. Beyond that, Matthias Kupperschmidt is liable only if the damage was caused due to gross negligence or wilful intent.</p>
          <p>Matthias Kupperschmidt is liable in the case of a slightly negligent breach of an essential contractual obligation and, in the case of non-contractual liability, only in the event of the loss of life, physical injury and damage to health, in the case of breach of guarantees, essential contractual obligations as well as in accordance with product liability law only in the amount applicable for typical damage that was foreseeable when the contract was signed. The damage is calculated on the basis of the average monthly order volume within the last six months. In particular, liability for slightly negligent breach of essential contractual obligations in the case of loss of profit, indirect damage and consequential damage is excluded in principle.</p>
          <p>Liability for consequential damage, indirect damage and/or loss of profit is excluded unless in the case of intentional behaviour. In terms of amounts, liability is limited to the expected normal damage in a case of loss within Denmark; at a maximum, however, to the overall remuneration to be paid by the contractual partner according to the respective order. This limitation of liability shall apply in the same way to damage caused by gross negligence or intent on the part of employees or agents of Matthias Kupperschmidt.</p>
          <p>In the event that the contractual partner has made changes to the contractual service or its environment or had such changes carried out by third parties, all claims of the contractual partner from the above provisions expire, unless the defect has not been caused, as a whole or in parts, by the changes carried out by the contractual partner and rectification is not made more difficult by the changes.</p>
          <p>These limitations of liability shall apply mutatis mutandis for all employees and agents of Matthias Kupperschmidt. Any liability of Matthias Kupperschmidt due to product liability law remains unaffected.</p>
          <p>All claims for damage or other claims that could be asserted against Matthias Kupperschmidt due to the properties and condition or defects of the service fall under the statute of limitation 6 (in words: six) months after the end of the respective contract.</p>
          <p>The contractual partner is liable with respect to Matthias Kupperschmidt for the content, documents, links and other materials (referred to as “documents” below) provided by him and made available to Matthias Kupperschmidt for the implementation of the collaboration project.</p>
          <p>If required, the contractual partner shall check at his own expense whether the services rendered by Matthias Kupperschmidt comply with relevant statutory provisions. Matthias Kupperschmidt will initiate such an external legal check only upon the express written request of the contractual partner; the associated costs shall be borne by the contractual partner.</p>
          <p>In the event that third parties assert claims against Matthias Kupperschmidt because of an infringement of copyrights and/or breach of relevant statutory provisions that occurred within the sphere of duties of the contractual partner, the contractual partner shall do everything in his power to defend Matthias Kupperschmidt against the asserted claims or rights and to eliminate the alleged infringement. The contractual partner is also liable with respect to Matthias Kupperschmidt for any damage caused by third parties that incurred as a consequence of the order having been executed partially, faultily, in a delayed manner, late or not at all. The same applies to any agents of the contractual partner. The contractual partner shall bear the burden of proof for proper execution of the order.</p>
          <p>The contractual partner releases Matthias Kupperschmidt from all claims for damage, liability claims and costs that Matthias Kupperschmidt incurs due to the fact that a claim is asserted against it according to which the documents of the contractual partner breach competition law, infringe upon the industrial property rights of third parties or other laws and regulations. In particular, the contractual partner shall release Matthias Kupperschmidt, at first request, immediately from any claims of third parties asserted against Matthias Kupperschmidt on account of the content used or an offer linked by hyperlink, especially under the Danish Data Protection Act (DDPA) and the General Data Protection Regulation (GDPR) of the EU and shall refund, upon request, any costs Matthias Kupperschmidt incurs due to appropriate legal action.</p>

          <p>Section 10 Admissibility of Web sites</p>
          <p>morefire does not check whether the content of Web sites of the client infringe upon the rights of third parties or are set up in accordance with the guidelines of the individual search machine operators. The client alone is responsible for any punishment by third parties.</p>
          <p>The client is also responsible for the legal admissibility of the content of his Web sites as well as for the information provided by him, including the search terms or keywords; the same applies to the protection of the rights of third parties, in particularly in terms of copyright, competition law and criminal law.</p>

          <p>Section 11 Service contract</p>
          <p>Employees deployed within the framework of service contracts are not permitted to be poached by the client within a period of 24 months after awarding of the contract. If such a solicitation takes place within this period, the contractor is entitled to a one-off payment in the amount of €75,000.</p>

          <p>Section 12 Data protection</p>
          <p>Without an additional declaration of consent, personal data – unless specified otherwise – is collected, processed and used only for the purpose of providing the services, the use of the services and, if applicable, for invoicing.</p>
          <p>The contractual partner is under obligation to comply with all applicable provisions of data protection, especially the provisions of the Danish Data Protection Act (DDPA) and the General Data Protection Regulation (GDPR) of the EU.</p>
          <p>The contractual partner guarantees to use data he might have collected exclusively for the stated purpose as well as for market research and advertising purposes within the company, insofar as it is required for the execution of the concluded transactions and the maintenance of the resulting user relationship and if it is permitted by law and desired by the user. Reselling data to third parties by the contractual partner is excluded, and charges will be pressed.</p>

          <p>Section 13 Confidentiality and secrecy</p>
          <p>The contractual partner is under obligation to use the documents, knowledge and experience gotten from Matthias Kupperschmidt solely for the purposes of this contract. In addition, the contracting parties agree on confidentiality with respect to third parties concerning the content of the agreements made as well as any knowledge gained during their implementation. The contractual partner is obligated to confer with Matthias Kupperschmidt should any doubts arise as to whether any information in a specific individual case is to be handled confidentially.</p>
          <p>In case of a possible subcontracting, the contractual partner shall pledge the respective sub-contractor to obligations within the meaning of this provision.</p>
          <p>The contractual partner shall ensure compliance with this obligation to maintain confidentiality with respect to his employees and any third parties involved in contract implementation who have access to the documents and objects specified in the first paragraph.</p>


          <p>Section 14 Changes</p>
          <p>Matthias Kupperschmidt shall provide his services in accordance with the respective product descriptions. Matthias Kupperschmidt reserves the right to modify, expand or adapt the services, as a whole or in part, or to modify, expand or adapt the type and content of individual products and services offered within the scope of the provision of the services at any time and repeatedly for the improvement of the offer, especially its functions, design and technical development. The contractual partner shall be informed of major changes to the products by e-mail in a timely manner. The new version of the product descriptions shall be attached. If the contractual partner does not agree to the change, he is entitled to terminate the contract with Matthias Kupperschmidt with immediate effect. If the contractual partner does not object to the change within two weeks, the notified changes are deemed the new conditions.</p>
          <p>In addition, Matthias Kupperschmidt is entitled to discontinue individual functions offered as part of the services – particularly in the case of modified statutory requirements, long-lasting losses, technical difficulties or in the event of misuses by customers, contractual partners or third parties.</p>
          <p>Matthias Kupperschmidt also expressly reserves the right to make price changes, of which Matthias Kupperschmidt shall inform the contractual partner within a reasonable period of time.</p>
          <p>Matthias Kupperschmidt also reserves the right to change or amend these GTC, especially in the event that new services are introduced or changes are required due to statutory provisions. Matthias Kupperschmidt shall notify the contractual partner of changes or additions to the GTC. If the contractual partner does not agree to the changes, he can object to them within two weeks after they were made known to him in writing.</p>
          <p>In the event that the contractual partner objects to changes to the services, these GTC or the special terms and conditions for the individual products, Matthias Kupperschmidt has the right to terminate without notice contracts that were concluded under these GTC. If the contractual partner does not object, his consent is deemed as given upon the expiration of the aforementioned periods. Matthias Kupperschmidt shall point out the length of the period and the significance of its expiration in the notification of the change to the GTC.</p>

          <p>Section 15 Final provisions</p>
          <p>Sending notifications to Matthias Kupperschmidt by e-mail complies with the written form requirement only if it is expressly provided for in these terms of use and if Matthias Kupperschmidt has expressly agreed to it beforehand.</p>
          <p>An assignment of contractual rights and obligations by the contractual partner to a third party is permitted only with the prior written consent of Matthias Kupperschmidt.</p>
          <p>The contractual partner has a right of retention only in terms of uncontested or legally established claims.</p>
          <p>There are no collateral agreements. Any agreements between the parties must be in writing. This also applies to the cancellation of the written form requirement.</p>
          <p>The contractual partner shall inform Matthias Kupperschmidt in due time of pending insolvency proceedings so as to ensure the fulfilment of the contractual services and obligations or else to allow for a consensual termination of contract.</p>
          <p>Should individual provisions of the General Terms and Conditions be ineffective as a whole or in parts or if they contain a loophole, the effectiveness of the remaining provisions or parts of such provisions remain unaffected by it. In such a case, the parties are obligated to participate in the creation of such new provisions, by means of which a commercial result is achieved with legal effect that comes closest to the ineffective or incomplete provision, while maintaining mutual interests.</p>
          <p>Place of performance for both parties is the place of business of Matthias Kupperschmidt.</p>
          <p>The law of the state of Denmark applies exclusively with the exclusion of the United Nations Convention on Contracts for the International Sale of Goods</p>
          <p>(CISG) and of references to foreign law.</p>
          <p>If the customer is a merchant, legal entity under public law or a special fund under public law or if he has no registered office in Denmark, the exclusive place of jurisdiction is Copenhagen, Denmark. Matthias Kupperschmidt is also entitled to file suit at the place of business of the contractual partner.</p>

          <p>Section 13 Dispute settlement</p>
          <p>The European Commission has set up a platform for the online settlement of disputes. It can be accessed at: http://ec.europa.eu/consumers/odr/.</p>
          <p>Consumers can use the platform for the settlement of disputes. Matthias Kupperschmidt is not willing or obligated to participate in dispute settlement proceedings before a Consumer Arbitration Body unless there is a legal obligation to participate.</p>
          <p>Copenhagen, 01. February 2019</p>




        </MainContent>
      </React.Fragment>

    </Layout>
  );
};
export default LegalNotice;
